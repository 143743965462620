:root {
  /* Coolors.io generated palettes */
  --rich-black: #0b2027ff;
  --cerulean: #40798cff;
  --verdigris: #70a9a1ff;
  --ash-gray: #cfd7c7ff;
  --cornsilk: #f6f1d1ff;
  --dartmouth-green: #0b6e4fff;
  --garnet: #754043ff;
  --fulvous: #e28413ff;
  --oxford-blue: #14213dff;
  --orange-web: #fca311ff;
  --platinum: #e5e5e5ff;

  --main-bg-color: black;

  background-color: var(--main-bg-color);

  --navbar-background-color: var(--oxford-blue);
  --navbar-text-color: var(--platinum);
  --navbar-text-active-color: var(--platinum);
  --navbar-text-hover-color: var(--orange-web);

  font-size: 1rem;
  color: white;
}

/* Images styling */
.image-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
  /* justify-content: center; */
}

.image-box {
  flex: 0 0 25%;
  position: relative;
  max-width: 25%;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}

.image-box img {
  width: 100%;
  height: 100%;
}

.image-box button {
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 17px;
  font-size: 50px;
  color: white;
  background-color: transparent;
  border: none;
  transition: all 0.1s;
  filter: brightness(0) saturate(100%) invert(47%) sepia(43%) saturate(3604%)
    hue-rotate(163deg) brightness(97%) contrast(101%);
}

.image-box button:hover {
  font-size: 55px;
  color: rgb(207, 161, 161);
}

h1 {
  font-size: 2rem;
  text-align: center;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .image-box {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  /* .image-box button {
    font-size: 45px;
  } */
}

@media only screen and (max-width: 720px) {
  .image-box {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 540px) {
  .image-box {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
