.nav {
  width: 100vw;
  background-color: var(--navbar-background-color);
  text-align: left;
  font-weight: bold;
  padding: 5px 0px;
}

nav a {
  color: var(--navbar-text-color);
  padding: 0.5em 1em;
  width: auto;
  display: inline-block;
  text-decoration: none;
}

nav a.active {
  color: var(--navbar-text-active-color);
  font-size: 1.1em;
  text-decoration: none;
}

nav a:hover {
  color: var(--navbar-text-hover-color);

  text-decoration: none;
}
